export const instalaciones = {
  41: {
    titulo: "Loteria de Santa Cruz Online",
    shortName: "loteriadesantacruz",
    color: "#662483",
    canonical: "https://sc.lotline.com.ar/plataforma/",
    manifest: "manifest.webmanifest",
    direccion: "Perito Moreno esquina Zapiola, Río Gallegos",
  },
  11: {
    titulo: "Loteria de Santa Cruz Online",
    shortName: "loteriadesantacruz",
    color: "#662483",
    canonical: "https://sc.lotline.com.ar/plataforma/",
    manifest: "manifest.webmanifest",
    direccion: "Perito Moreno esquina Zapiola, Río Gallegos",
  },
  42: {
    titulo: "La Neuquina Online",
    shortName: "laneuquinaonline",
    color: "#FFCC00",
    canonical: "https://www.laneuquinaonline.com.ar/plataforma/",
    manifest: "manifest.webmanifest",
    direccion: "Carlos H. Rodríguez 146, Neuquén",
    urlTelebingo:
      "https://issuu.com/arteymania/docs/manual_apostador__2018_-_corregido_/24",
  },
  12: {
    titulo: "La Neuquina Online",
    shortName: "laneuquinaonline",
    color: "#FFCC00",
    canonical: "https://www.laneuquinaonline.com.ar/plataforma/",
    manifest: "manifest.webmanifest",
    direccion: "Carlos H. Rodríguez 146, Neuquén",
    urlTelebingo:
      "https://issuu.com/arteymania/docs/manual_apostador__2018_-_corregido_/24",
  },
  53: {
    titulo: "Loteria de Salta Online",
    shortName: "loteriadesalta",
    color: "#5601b7",
    canonical: "https://www.loteriadesaltaonline.com.ar/plataforma/",
    manifest: "manifest.webmanifest",
    direccion: "Balcarce 759, Salta",
  },
  23: {
    titulo: "Loteria de Salta Online",
    shortName: "loteriadesalta",
    color: "#5601b7",
    canonical: "https://www.loteriadesaltaonline.com.ar/plataforma/",
    manifest: "manifest.webmanifest",
    direccion: "Balcarce 759, Salta",
  },
  43: {
    titulo: "La Rionegrina Online",
    shortName: "larionegrinaonline",
    color: "#5601b7",
    canonical: "https://www.loteriadesaltaonline.com.ar/plataforma/",
    manifest: "manifest.webmanifest",
    direccion:
      "Sede Central: 25 de Mayo y Sarmiento (8500) - Viedma - Río Negro",
  },
  13: {
    titulo: "La Rionegrina Online",
    shortName: "larionegrinaonline",
    color: "#5601b7",
    canonical: "https://www.loteriadesaltaonline.com.ar/plataforma/",
    manifest: "manifest.webmanifest",
    direccion:
      "Sede Central: 25 de Mayo y Sarmiento (8500) - Viedma - Río Negro",
  },
  50: {
    titulo: "Loteria de Corrientes Online",
    shortName: "loteriadecorrientesonline",
    color: "#FFCC00",
    canonical: "https://www.lacorrentinaonline.bet.ar/plataforma/",
    manifest: "manifest.webmanifest",
    direccion: "Instituto de Lotería y Casinos de Corrientes - Cordoba 915 - CP W3400CDM - (379) 4476213 - Corrientes, Argentina"
  },
  20: {
    titulo: "Loteria de Corrientes Online",
    shortName: "loteriadecorrientesonline",
    color: "#FFCC00",
    canonical: "https://www.lacorrentinaonline.bet.ar/plataforma/",
    manifest: "manifest.webmanifest",
    direccion: "Instituto de Lotería y Casinos de Corrientes - Cordoba 915 - CP W3400CDM - (379) 4476213 - Corrientes, Argentina"
  },
  48: {
    titulo: "La Jujeñita Online",
    shortName: "lajujeñitaonline",
    color: "#a32c32",
    canonical: "https://www.lajujeñitaonline.bet.ar/plataforma/",
    manifest: "manifest.webmanifest",
    direccion: ""
  },
  18: {
    titulo: "La Jujeñita Online",
    shortName: "lajujeñitaonline",
    color: "#a32c32",
    canonical: "https://www.lajujeñitaonline.bet.ar/plataforma/",
    manifest: "manifest.webmanifest",
    direccion: ""
  },

};
