import React, { useEffect, useRef, useState } from "react";
import $ from "jquery";
import "jquery-confirm/css/jquery-confirm.css";
import "jquery-confirm";
import { useHistory, useParams, withRouter } from "react-router-dom";
import ManualJuego from "../components/manualJuego";
import TraerCuponesPendientesDesktop from "../components/traerCuponesPendientesDesktop";

const Juego = (props) => {
  //----------------HOOKS DE ESTADO -----------------------
  const [urlIframeJuego, seturlIframeJuego] = useState(undefined);
  const { nombrejuego } = useParams();
  const [mandoMsj, setMandoMsj] = useState(false);
  const iframeRef = useRef();
  const [iframeContent, setIframeContent] = useState();

  //----------------- HISTORY -------------------------------
  const history = useHistory();

  //------------------ HOOKS DE EFECTO ----------------------
  useEffect(() => {
    if (props.estadoPlataforma.estado === "PLATAFORMA") {
      history.push("/home");
    }
  });

  useEffect(() => {
    switch (nombrejuego) {
      case "Quinielatradicional":
        seturlIframeJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_TOMBOLA
        );
        break;
      case "Quiniela Instantánea":
        seturlIframeJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_TOMBO_EXPRESS
        );
        break;
      case "quinielainstantanea":
        seturlIframeJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_TOMBO_EXPRESS
        );
        break;
      case "patagoniaminibingo":
        seturlIframeJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_PATA_MINIBINGO
        );
        break;
      case "patagoniatelebingo":
        seturlIframeJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_PATA_TELEBINGO
        );
        break;
      case "Tombola":
        seturlIframeJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_TOMBOLA
        );
        break;
      case "QuiniExpress":
        seturlIframeJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_TOMBO_EXPRESS
        );
        break;
      case "TomboExpress":
        seturlIframeJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_TOMBO_EXPRESS
        );
        break;
      case "pozodelaquiniela":
        seturlIframeJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_POZO
        );
        break;
      case "loteriaunificada":
        seturlIframeJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_LOTERIA_UNIFICADA
        );
        break;
      case "raspadita_money":
        seturlIframeJuego(process.env.REACT_APP_URL_JUEGO_RASPADITA);
        window.setmanualJuego(
          <ManualJuego
            className="navbar-brand"
            manualesJuego="https://issuu.com/arteymania/docs/manual_apostador__2018_-_corregido_/28?fr=sZGIzNjM2ODMyNzU "
            style={{ zIndex: "999" }}
          />
        );
        window.setnotificacionesJuego(
          <TraerCuponesPendientesDesktop
            className="mr-4"
            pendientes={0 /*cuponesPend.cuponesSinSortear*/}
            traerCupones={() => console.log()}
            refreshCup={() => console.log()}
            juego={nombrejuego}
            estadoPlataforma={props.estadoPlataforma}
          />
        );
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    props.arrancarJuego(urlIframeJuego);

    if (
      urlIframeJuego !== undefined &&
      nombrejuego === "raspadita_money" &&
      !mandoMsj
    ) {
      setTimeout(() => {
        let juego = iframeRef.current.contentWindow;
        juego.postMessage(
          props.estadoPlataforma.userToken,
          process.env.REACT_APP_URL_JUEGO_RASPADITA
        );

        window.addEventListener("message", (e) => {
          //console.log(e);
          if (e.data === "ActualizoSaldoMoney") {
            setTimeout(() => {
              window.refreshSaldo();
            }, 1000);
          }
        });
        setMandoMsj(true);
      }, 1000);
    }
  }, [urlIframeJuego, nombrejuego]);

  /**
   * Se ejecuta la funcion @terminoCargaJuego proveniente del componente padre
   */
  const cargoElIFrame = () => {
    props.terminoCargaJuego();
  };

  return props.estadoPlataforma.estado === "PLATAFORMA" ? (
    <></>
  ) : (
    <>
      {urlIframeJuego !== undefined && (
        <iframe
          ref={iframeRef}
          onLoad={cargoElIFrame}
          src={urlIframeJuego}
          style={{ visibility: "visible" }}
          title="juego"
          id="zonaJuego"
          className={`zonaJuego_${nombrejuego}`}
          frameBorder="0"
        />
      )}

      {/*  <iframe
        ref={iframeRef}
        src={"http://localhost:3001"}
        style={{ visibility: "visible" }}
        title="prueba"
        id="zonaJuego"
      />*/}
    </>
  );
};

export default withRouter(Juego);
