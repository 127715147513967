import React, { useRef, useState } from "react";
import { useEffect } from "react";
import $ from "jquery";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Animated } from "react-animated-css";
import NumberFormat from "react-number-format";
import { useMediaQuery } from "react-responsive";
import ReactHtmlParser from "react-html-parser";
import CerrarSesion from "./cerrarSesion";
import Calificar from "./calificar";
import styled from "styled-components";
import SideBarMenu from "./sideBarMenu";
import BottomNavMobile from "./components/nav_tools/bottomNavMobile";
import { estilosProv } from "./configProvincias";
import Burgermenu from "./tools/burgermenu";
import ModalNotificaciones from "./tools/modalNotificaciones";
import LlamadorJuego from "./tools/home/llamadorJuego";
import TopNavDesktop from "./components/nav_tools/topNavDesktop";

const NavBar = (props) => {
  const [menu, setmenu] = useState(false);
  const [currentPage, setcurrentPage] = useState("");
  const [color, setcolor] = useState("");
  const [widthLogo, setwidthLogo] = useState("");
  const [logoMargin, setlogoMargin] = useState("");
  const [mensajesSinLeer, setmensajesSinLeer] = useState("");
  const [apellido, setapellido] = useState("");
  const [mensajesGenerales, setmensajesGenerales] = useState([]);
  const [juegoResponsable, setjuegoResponsable] = useState({
    titulo: "",
    jugadoJornada: 0,
    jugadoSesion: 0,
    balanceJornada: 0,
    balanceSesion: 0,
    premiosJornada: 0,
    premiosSesion: 0,
    horasSesion: 0,
    minutosSesion: 0,
  });
  const [nps, setnps] = useState({
    sugerirNPS: true,
    modulo: "APP",
    version: "1.0",
    titulo: "¿Que opina de la APP?",
  });
  const [userToken, setuserToken] = useState(props.estadoPlataforma.userToken);
  const [errorGeo, setErrorGeo] = useState(true);
  const [textErrorGeo, setTextErrorGeo] = useState(undefined);
  const [requiereGeo, setrequiereGeo] = useState(undefined);
  const [latitud, setlatitud] = useState(undefined);
  const [longitud, setlongitud] = useState(undefined);
  const [permiteCambioAg, setpermiteCambioAg] = useState(undefined);
  const [error, seterror] = useState(false);
  const [logoJuego0, setlogoJuego0] = useState(undefined);
  const [logoJuego1, setlogoJuego1] = useState(undefined);
  const [logoJuego2, setlogoJuego2] = useState(undefined);
  const [logoJuego3, setlogoJuego3] = useState(undefined);
  const [logoJuego4, setlogoJuego4] = useState(undefined);
  const [logoJuego5, setlogoJuego5] = useState(undefined);
  const [logoJuego6, setlogoJuego6] = useState(undefined);
  const [cantCuponesJuego6, setcantCuponesJuego6] = useState("");
  const [cantCuponesJuego10, setcantCuponesJuego10] = useState("");
  const [cargando, setCargando] = useState(true);
  const [userCuil, setuserCuil] = useState(null);
  const [wrapperRef, setwrapper] = useState(undefined);
  const [requiereCuil21, setrequiereCuil21] = useState(undefined);
  const [loadPataTelebingo, setloadPataTelebingo] = useState(undefined);
  const codOrganizacion = process.env.REACT_APP_COD_ORGANIZACION;
  const colorFondo = estilosProv[codOrganizacion].fondo;
  const colorFont = estilosProv[codOrganizacion].fontColor;
  const [isNavBar, setIsNavBar] = useState(true);
  const [mostrarSaldo, setMostrarSaldo] = useState(
    //["18", "48"].includes(codOrganizacion) ? false : true
    true
  );
  const [estadoOjo, setEstadoOjo] = useState(false);

  useEffect(() => {
    if (requiereGeo && requiereGeo !== undefined && false) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setlatitud(position.coords.latitude.toString());
        setlongitud(position.coords.longitude.toString());
      });
    }
  }, [requiereGeo]);

  useEffect(() => {
    if (
      latitud !== undefined &&
      longitud !== undefined &&
      requiereGeo &&
      false
    ) {
      requiereGeolocalizacion();
    }
  }, [latitud, longitud]);

  const [size, setSize] = useState(window.innerWidth);
  const [height, setheight] = useState(window.innerHeight);

  window.refreshMensajeria = () => {
    inicializarMensGral();
  };

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
      setSize(newWidth);
      setheight(newHeight);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, [size, height]);

  useEffect(() => {
    let c = document.getElementById("newGameModalDesktop");
    if (c.className.includes("show")) {
      $("#newGameModalDesktop").removeClass("show");
    }
  });

  const traerDatosJuegoResponsable = () => {
    let parametros = {
      userToken: props.estadoPlataforma.userToken,
    };

    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_DATOS_JUEGO_RESPONSABLE;

    let statusCode = "";

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + parametros.userToken,
      },
    })
      .then((respPromise) => {
        statusCode = respPromise.status;
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          props.cerrarSesion();
          props.history.push("/");
          sessionStorage.removeItem("Usuario");
          sessionStorage.removeItem("login");
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          setjuegoResponsable({
            titulo: json.titulo,
            jugadoJornada: json.jugadoJornada,
            jugadoSesion: json.jugadoSesion,
            balanceJornada: json.balanceJornada,
            balanceSesion: json.balanceSesion,
            premiosJornada: json.premiosJornada,
            premiosSesion: json.premiosSesion,
            horasSesion: json.horasSesion,
            minutosSesion: json.minutosSesion,
          });
        } else if (json.status === "error") {
          props.history.push("/");
          sessionStorage.removeItem("Usuario");
          sessionStorage.removeItem("login");
        }
      })
      .catch((error) => {});
  };

  const traerMisDatosPersonales = () => {
    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_MIS_DATOS_PERSONALES;

    let cerrarSesion = false;

    let statusCode = "";

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + props.estadoPlataforma.userToken,
      },
    })
      .then((respPromise) => {
        statusCode = respPromise.status;
        if (respPromise.status === process.env.REACT_APP_CODIGO_CERRO_SESION) {
          cerrarSesion = true;
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          setapellido(json.apellido);
          setrequiereGeo(json.requiereGeo);
          setuserCuil(json.cuil);
          window.setCuil(json.cuil);
          if (json.cuil === null) {
            lanzarModalCuil();
          }
        }

        if (json.permiteCambioAg != undefined) {
          setpermiteCambioAg(json.permiteCambioAg);
        } else if (json.status === "error") {
          seterror(true);
        }
        if (cerrarSesion) {
          props.mensajeErrorWS(
            "Consulta datos personales",
            json.errores,
            cerrarSesion
          );
        }
      })
      .catch((error) => {
        seterror(true);
        if (props.mensajeErrorGeneral) props.mensajeErrorGeneral();
        sessionStorage.removeItem("Usuario");
        sessionStorage.removeItem("login");
      });
  };

  const handleGeoLocalizacion = (funcion) => {
    /* if ((errorGeo && requiereGeo) || requiereGeo === undefined) {
      props.mensajeErrorWS(
        "Localizacion",
        [
          {
            error:
              textErrorGeo === undefined
                ? "Cargando datos, intente nuevamente en unos segundos"
                : textErrorGeo,
          },
        ],
        false
      );
    } else {
      funcion();
    }*/
    funcion();
  };

  const requiereGeolocalizacion = () => {
    if (requiereGeo && requiereGeo !== undefined && false) {
      let url =
        process.env.REACT_APP_URL_BASE_BACKEND +
        process.env.REACT_APP_WS_DATOS_REQUIERE_GEO;

      fetch(
        `${url}?lat=${latitud}&lon=${longitud}&codigoOrganizacion=${process.env.REACT_APP_COD_ORGANIZACION}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: "Bearer " + props.estadoPlataforma.userToken,
          },
        }
      )
        .then((respPromise) => {
          return respPromise.json();
        })
        .then((res) => {
          if (res.status === "error") {
            setErrorGeo(false);
            //setErrorGeo(true);
            //setTextErrorGeo(res.errores[0].error);
          } else {
            setErrorGeo(false);
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  };

  const cargarImgDinamica = (
    nombre0,
    nombre1,
    nombre2,
    nombre3,
    nombre4,
    nombre5,
    numero6
  ) => {
    import(
      `./img/${nombre0}_${process.env.REACT_APP_COD_ORGANIZACION}.png`
    ).then((image) => {
      setlogoJuego0(image.default);
    });
    import(
      `./img/${nombre1}_${process.env.REACT_APP_COD_ORGANIZACION}.png`
    ).then((image) => {
      setlogoJuego1(image.default);
    });
    import(
      `./img/${nombre2}_${process.env.REACT_APP_COD_ORGANIZACION}.png`
    ).then((image) => {
      setlogoJuego2(image.default);
    });
    import(
      `./img/${nombre3}_${process.env.REACT_APP_COD_ORGANIZACION}.png`
    ).then((image) => {
      setlogoJuego3(image.default);
    });
    import(
      `./img/${nombre4}_${process.env.REACT_APP_COD_ORGANIZACION}.png`
    ).then((image) => {
      setlogoJuego4(image.default);
    });
    import(
      `./img/${nombre5}_${process.env.REACT_APP_COD_ORGANIZACION}.png`
    ).then((image) => {
      setlogoJuego5(image.default);
    });
    process.env.REACT_APP_ENABLE_RASPADITA !== undefined
      ? import(`./img/LOGO_RASPADITA_${codOrganizacion}.png`).then((image) => {
          setlogoJuego6(image.default);
        })
      : import(`./img/LOGO_RASPADITA.png`).then((image) => {
          setlogoJuego6(image.default);
        });
  };

  const irARaspadita = () => {
    if (userCuil === null || userCuil === "") {
      lanzarModalCuil();
    } else {
      props.actuVersionSiCorresponde(props);
      if (props.estadoPlataforma.logueado) {
        props.arrancarJuego(process.env.REACT_APP_URL_JUEGO_RASPADITA);
      } else {
        if (typeof props.requirioLoginJuego === "undefined") {
        } else {
          props.requirioLoginJuego(process.env.REACT_APP_URL_JUEGO_RASPADITA);
          props.history.push("/login");
        }
      }
    }
  };

  const getDatosInicialesJuegoPozoDeLaQuiniela = () => {
    let parametros = {
      userToken: props.estadoPlataforma.userToken,
    };

    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_INIT_JUEGO_POZO +
      "?codigoJuego=";
    let statusCode = "";

    fetch(url);
  };

  const getDatosInicialesJuegoMiniTeleBingo = (codigoJuego) => {
    //let codigoJuego = "20";

    let parametros = {
      userToken: props.estadoPlataforma.userToken,
    };

    let url = "";

    if (
      codigoJuego === "20" ||
      (codigoJuego === "21" && process.env.REACT_APP_ENABLE_PATA_MINIBINGO)
    ) {
      url =
        process.env.REACT_APP_URL_BASE_BACKEND +
        process.env.REACT_APP_WS_INIT_JUEGO_BINGO +
        "?codigoJuego=" +
        codigoJuego;
    } else {
      url =
        process.env.REACT_APP_URL_BASE_BACKEND +
        process.env.REACT_APP_WS_INIT_JUEGO_QUINIELA +
        "?codigoJuego=" +
        codigoJuego;
    }

    let statusCode = "";

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + parametros.userToken,
      },
    })
      .then((respPromise) => {
        statusCode = respPromise.status;
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          props.cerrarSesion();
          props.history.push("/");
          sessionStorage.removeItem("Usuario");
          sessionStorage.removeItem("login");
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          if (json.requiereCuil === false && userCuil !== null) {
            if (codigoJuego === "21") {
              clickPataTeleBingo();
            } else {
              clickPataMinibingo();
            }
          } else {
            altaCuil();
          }
        } else if (json.status === "error") {
          props.history.push("/");
          sessionStorage.removeItem("Usuario");
          sessionStorage.removeItem("login");
        }
      })
      .catch((error) => {});
  };

  const altaCuil = () => {
    let objThis = this;

    $.confirm({
      title: '<b className="op-text">Requerimos su CUIL </b>',
      backgroundDismiss: true,
      columnClass: "medium",
      animation: "zoom",
      closeIcon: true,
      closeAnimation: "scale",
      content: `<div id="miniBingoConfirm" > Favor por única vez, ingrese al juego utilizando el icono principal </div>`,
      buttons: {
        Si: {
          text: "Aceptar",
          action: function () {},
        },
      },
      onContentReady: function () {
        $("#cuilAltaCliente").mask("00-00000000-0", {
          placeholder: "00-00000000-0",
        });
      },
    });
  };

  const processarMiniBingo = () => {
    getDatosInicialesJuegoMiniTeleBingo("20");
  };

  const processarTeleBingo = () => {
    getDatosInicialesJuegoMiniTeleBingo("21");
  };

  const clickPataMinibingo = () => {
    if (userCuil === null || userCuil === "") {
      lanzarModalCuil();
    } else {
      var str =
        process.env.REACT_APP_URL_BASE_FRONTEND +
        process.env.REACT_APP_URL_JUEGO_PATA_MINIBINGO;
      if (str.slice(-1) === "/") str = str.slice(0, -1);
      var n = str.lastIndexOf("/");
      var nomJuego = str.substring(n + 1);

      if (props.estadoPlataforma.logueado) {
        props.arrancarJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_PATA_MINIBINGO
        );
      } else {
        if (typeof props.requirioLoginJuego === "undefined") {
        } else {
          props.requirioLoginJuego(
            process.env.REACT_APP_URL_BASE_FRONTEND +
              process.env.REACT_APP_URL_JUEGO_PATA_MINIBINGO
          );
          props.history.push("/login");
        }
      }
    }
  };

  /**
   * esta funcion hará que en el caso que el cuil del usuario sea null, a traves de jquery se lanzará
   * un modal donde el usuario deberá ingresar su cuil y aceptar. Al aceptar se ejecutara la funcion
   * @altaCuilCliente
   */
  const lanzarModalCuil = () => {
    $("#cuilAltaCliente").mask("00-00000000-0", {
      placeholder: "00-00000000-0",
    });
    $.confirm({
      title:
        '<b className="op-text" id="tituloMiniBingoConfirm" >Favor ingrese su CUIL/CUIT</b>',
      backgroundDismiss: true,
      columnClass: "medium",
      animation: "zoom",
      closeIcon: true,
      closeAnimation: "scale",
      content: `<div style="text-align: center;" ><input id="cuilAltaClienteTele" maxlength="13"  /></div> `,
      buttons: {
        No: {
          text: "Cancelar",
          action: function () {},
        },
        Si: {
          text: "Aceptar",
          action: function () {
            let cuil = $("#cuilAltaClienteTele").val();

            altaCuilCliente(cuil, "21");
          },
        },
      },
      onContentReady: function () {
        $("#cuilAltaCliente").mask("00-00000000-0", {
          placeholder: "00-00000000-0",
        });
      },
    });
  };

  const clickPataTeleBingo = () => {
    if (userCuil === null || userCuil === "") {
      lanzarModalCuil();
    } else {
      var str =
        process.env.REACT_APP_URL_BASE_FRONTEND +
        process.env.REACT_APP_URL_JUEGO_PATA_TELEBINGO;
      if (str.slice(-1) === "/") str = str.slice(0, -1);
      var n = str.lastIndexOf("/");
      var nomJuego = str.substring(n + 1);

      if (props.estadoPlataforma.logueado) {
        props.arrancarJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_PATA_TELEBINGO
        );
      } else {
        if (typeof props.requirioLoginJuego === "undefined") {
        } else {
          props.requirioLoginJuego(
            process.env.REACT_APP_URL_BASE_FRONTEND +
              process.env.REACT_APP_URL_JUEGO_PATA_TELEBINGO
          );
          props.history.push("/login");
        }
      }
    }
  };

  const irAPataTeleBingo = () => {
    props.setJuegoActual({
      codigo: 21,
      cuponesSinLeer: 0,
    });
    //let objThis = this;
    setloadPataTelebingo(true);
    props.actuVersionSiCorresponde(props);

    var str =
      process.env.REACT_APP_URL_BASE_FRONTEND +
      process.env.REACT_APP_URL_JUEGO_PATA_TELEBINGO;
    if (str.slice(-1) === "/") str = str.slice(0, -1);
    var n = str.lastIndexOf("/");
    var nomJuego = str.substring(n + 1);

    if (props.estadoPlataforma.logueado) {
      props.arrancarJuego(
        process.env.REACT_APP_URL_BASE_FRONTEND +
          process.env.REACT_APP_URL_JUEGO_PATA_TELEBINGO
      );
    } else {
      if (typeof props.requirioLoginJuego === "undefined") {
      } else {
        props.requirioLoginJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_PATA_TELEBINGO
        );
        //this.props.history.push("/login");
      }
    }
  };

  const irAPozoQuiniela = () => {
    props.actuVersionSiCorresponde(props);

    var str =
      process.env.REACT_APP_URL_BASE_FRONTEND +
      process.env.REACT_APP_URL_JUEGO_POZO;
    if (str.slice(-1) === "/") str = str.slice(0, -1);

    if (props.estadoPlataforma.logueado) {
      props.arrancarJuego(
        process.env.REACT_APP_URL_BASE_FRONTEND +
          process.env.REACT_APP_URL_JUEGO_POZO
      );
    } else {
      if (typeof props.requirioLoginJuego === "undefined") {
      } else {
        props.requirioLoginJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_POZO
        );
        props.history.push("/login");
      }
    }
  };

  const irALoteriaUnificada = () => {
    props.actuVersionSiCorresponde(props);

    var str =
      process.env.REACT_APP_URL_BASE_FRONTEND +
      process.env.REACT_APP_URL_JUEGO_LOTERIA_UNIFICADA;
    if (str.slice(-1) === "/") str = str.slice(0, -1);

    if (props.estadoPlataforma.logueado) {
      props.arrancarJuego(
        process.env.REACT_APP_URL_BASE_FRONTEND +
          process.env.REACT_APP_URL_JUEGO_LOTERIA_UNIFICADA
      );
    } else {
      if (typeof props.requirioLoginJuego === "undefined") {
      } else {
        props.requirioLoginJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_LOTERIA_UNIFICADA
        );
        props.history.push("/login");
      }
    }
  };

  const clickPozoQuiniela = () => {
    let objThis = this;

    if (userCuil === null || userCuil === "") {
      lanzarModalCuil();
    } else {
      setCargando(true);

      irAPozoQuiniela();
    }
  };

  const clickLoteriaUnificada = () => {
    let objThis = this;

    if (userCuil === null || userCuil === "") {
      lanzarModalCuil();
    } else {
      setCargando(true);

      irALoteriaUnificada();
    }
  };

  const clickTombola = () => {
    var str =
      process.env.REACT_APP_URL_BASE_FRONTEND +
      process.env.REACT_APP_URL_JUEGO_TOMBOLA;
    if (str.slice(-1) === "/") str = str.slice(0, -1);
    var n = str.lastIndexOf("/");
    var nomJuego = str.substring(n + 1);

    if (props.estadoPlataforma.logueado) {
      props.arrancarJuego(
        process.env.REACT_APP_URL_BASE_FRONTEND +
          process.env.REACT_APP_URL_JUEGO_TOMBOLA
      );
    } else {
      if (typeof props.requirioLoginJuego === "undefined") {
      } else {
        props.requirioLoginJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_TOMBOLA
        );
        props.history.push("/login");
      }
    }
  };

  const clickTombolaExpress = () => {
    var str =
      process.env.REACT_APP_URL_BASE_FRONTEND +
      process.env.REACT_APP_URL_JUEGO_TOMBO_EXPRESS;
    if (str.slice(-1) === "/") str = str.slice(0, -1);
    var n = str.lastIndexOf("/");
    var nomJuego = str.substring(n + 1);

    if (props.estadoPlataforma.logueado) {
      props.arrancarJuego(
        process.env.REACT_APP_URL_BASE_FRONTEND +
          process.env.REACT_APP_URL_JUEGO_TOMBO_EXPRESS
      );
    } else {
      if (typeof props.requirioLoginJuego === "undefined") {
      } else {
        props.requirioLoginJuego(
          process.env.REACT_APP_URL_BASE_FRONTEND +
            process.env.REACT_APP_URL_JUEGO_TOMBO_EXPRESS
        );
        props.history.push("/login");
      }
    }
  };

  const altaCuilCliente = (aCuil, codjuego) => {
    let parametros = {
      cuil: aCuil,
    };

    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_CAMBIAR_DATOS_PERSONALES;

    let cerrarSesion = false;

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + props.estadoPlataforma.userToken,
      },
      body: JSON.stringify(parametros),
    })
      .then((respPromise) => {
        if (respPromise.status === process.env.REACT_APP_CODIGO_CERRO_SESION) {
          cerrarSesion = true;
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          setuserCuil(json.cuil);
          window.setCuil(json.cuil);
          traerMisDatosPersonales();
        } else if (json.status === "error") {
          if (cerrarSesion) {
            props.mensajeErrorWS("CUIL inválido ", json.errores, cerrarSesion);
          } else {
            props.mensajeErrorWS("CUIL inválido ", json.errores);
          }
        }
      })
      .catch((error) => {
        props.mensajeErrorGeneral();
        sessionStorage.removeItem("Usuario");
        sessionStorage.removeItem("login");
      });
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (props.redirect !== "/") {
      props.history.push(props.redirect);
    }

    if (requiereGeo && requiereGeo !== undefined) {
      requiereGeolocalizacion();
    }
    traerMisDatosPersonales();
    cargarImgDinamica(
      "LOGO_JUEGO_0",
      "LOGO_JUEGO_1",
      "LOGO_JUEGO_20",
      "LOGO_JUEGO_21",
      "LOGO_JUEGO_6",
      "LOGO_JUEGO_10"
    );

    inicializarMensGral();

    /*
          var idVarMens = setInterval(() => { 
              inicializarMensGral() 
            }, 1000*60*10);
          setState({idIntervalMens : idVarMens});
          */
    handleLogo();

    function testAnim(x) {
      $("#newGameModal .modal-dialog").attr(
        "class",
        "modal-dialog  " + x + "  animated"
      );
      $("#newGameModalDesktop .modal-dialog").attr(
        "class",
        "modal-dialog  " + x + "  animated"
      );
    }

    $("#newGameModal").on("show.bs.modal", function (e) {
      testAnim("slideInUp");
    });
    $("#newGameModal").on("hide.bs.modal", function (e) {
      testAnim("slideOutDown");
    });

    $("#newGameModalDesktop").on("show.bs.modal", function (e) {
      testAnim("slideInLeft");
    });
    $("#newGameModalDesktop").on("hide.bs.modal", function (e) {
      testAnim("slideOutLeft");
    });
  }, []);

  useEffect(() => {
    handleVisited();
    document.addEventListener("mousedown", (e) => {
      let b = document.getElementById("newGameModalDesktop");
      if (b !== null) {
        if (b.className === "modal fade show") {
          $(".BtnNewGame").click();
        }
      }
    });
  }, [props.currentPage]);

  const handleVisited = () => {
    setcurrentPage(props.currentPage);
    //console.log(props.currentPage);
    let homeIcon = $(".home-icon");
    let homeText = $(".home-text");
    let billeteraIcon = $(".billetera-icon");
    let billeteraText = $(".billetera-text");
    let retirarIcon = $(".retirar-icon");
    let retirarText = $(".retirar-text");
    let userIcon = $(".user-icon");
    let userText = $(".user-text");

    // eslint-disable-next-line default-case
    switch (props.currentPage) {
      case "home":
        homeIcon.addClass("nav-visited");
        homeText.addClass("nav-visited");
        billeteraIcon.removeClass("nav-visited");
        billeteraText.removeClass("nav-visited");
        retirarIcon.removeClass("nav-visited");
        retirarText.removeClass("nav-visited");
        userIcon.removeClass("nav-visited");
        userText.removeClass("nav-visited");
        break;

      case "billetera":
        homeIcon.removeClass("nav-visited");
        homeText.removeClass("nav-visited");
        billeteraIcon.addClass("nav-visited");
        billeteraText.addClass("nav-visited");
        retirarIcon.removeClass("nav-visited");
        retirarText.removeClass("nav-visited");
        userIcon.removeClass("nav-visited");
        userText.removeClass("nav-visited");
        break;

      case "retirar":
        homeIcon.removeClass("nav-visited");
        homeText.removeClass("nav-visited");
        billeteraIcon.removeClass("nav-visited");
        billeteraText.removeClass("nav-visited");
        retirarIcon.addClass("nav-visited");
        retirarText.addClass("nav-visited");
        userIcon.removeClass("nav-visited");
        userText.removeClass("nav-visited");
        break;

      case "usuario":
        homeIcon.removeClass("nav-visited");
        homeText.removeClass("nav-visited");
        billeteraIcon.removeClass("nav-visited");
        billeteraText.removeClass("nav-visited");
        retirarIcon.removeClass("nav-visited");
        retirarText.removeClass("nav-visited");
        userIcon.addClass("nav-visited");
        userText.addClass("nav-visited");
        break;
    }
  };

  const consultarBilletera = () => {
    if (props.refreshConsultaBilletera.laFuncion != null)
      props.refreshConsultaBilletera.laFuncion();
    //props.history.push('/consultaBilletera?a='+Math.random());
  };

  const closeNotificacion = () => {
    inicializarMensGral();
  };

  const handleLogo = () => {
    /* 
        
        process.env.REACT_APP_COD_ORGANIZACION === "41"  &&
        setState({widthLogo:"180px", logoMargin:"6%"}) // SANTA CRUZ

        process.env.REACT_APP_COD_ORGANIZACION === "11"  &&
        setState({widthLogo:"180px", logoMargin:"6%"}) // SANTA CRUZ

        process.env.REACT_APP_COD_ORGANIZACION === "43" &&
        setState({widthLogo:"180px", logoMargin:"6%"}) // RIO NEGRO

        process.env.REACT_APP_COD_ORGANIZACION === "46" &&
        setState({widthLogo:"38px", logoMargin:"31%"}) // LA RIOJA

        process.env.REACT_APP_COD_ORGANIZACION === "49" &&
        setState({widthLogo:"70px", logoMargin:"25%"}) // SANTIAGO

        process.env.REACT_APP_COD_ORGANIZACION === "50"  &&
        setState({widthLogo:"180px", logoMargin:"6%"}) // CORRIENTES

        process.env.REACT_APP_COD_ORGANIZACION === "53"  &&
        setState({widthLogo:"160px", logoMargin:"10%"}) // SALTA
        */

    window.parent.getCodigoOrganizacion() === "42" && setcolor("#212529");

    window.parent.getCodigoOrganizacion() === "42" && setcolor("#212529");
  };

  const refsetWrapperRef = useRef();

  const toggleMenu = () => {
    props.refreshSaldo();

    let menuLocal = $("#burgerMenu");
    $("#solapa").removeClass("op");
    if (!menu) {
      menuLocal.removeClass("init-burger");
      menuLocal.addClass("end-burger");
    } else {
      menuLocal.addClass("init-burger");
      menuLocal.removeClass("end-burger");
      menuLocal.remove("show");
    }

    setmenu(!menu);
  };

  const handleClickOutside = (ev) => {
    if (
      !(
        ev.target.getAttribute("ayuda") === "ayuda" ||
        ["svg", "path"].includes(ev.target.tagName) ||
        [
          "ayuda",
          "iconify mr-2",
          "iconify help-arrow",
          "collapse p-0",
          "ayuda-cont",
          "collapsed",
        ].includes(ev.target.className)
      )
    ) {
      let menuLocal = $("#burgerMenu");
      if (menuLocal.hasClass("end-burger")) {
        menuLocal.removeClass("end-burger");
        menuLocal.addClass("init-burger");
        setmenu(false);
      }
    }
  };

  const handleClickOjoSaldo = () => {
    setMostrarSaldo(!mostrarSaldo);
    setEstadoOjo(!estadoOjo);
  };

  const traerMensGrales = () => {
    let parametros = {
      userToken: props.estadoPlataforma.userToken,
    };

    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_LEER_MENSAJERIA_GRAL;

    let statusCode = "";

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + parametros.userToken,
      },
    })
      .then((respPromise) => {
        statusCode = respPromise.status;
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
          props.cerrarSesion();
          props.history.push("/");
          sessionStorage.removeItem("Usuario");
          sessionStorage.removeItem("login");
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          setmensajesGenerales(json.mensajes);
        } else if (json.status === "error") {
          props.history.push("/");
          sessionStorage.removeItem("Usuario");
          sessionStorage.removeItem("login");
        }
      })
      .catch((error) => {});
  };

  const clickRefrescarSaldo = () => {
    if (currentPage === "retirar") {
      props.history.push("/temp");
      props.history.goBack();
    }

    if (currentPage === "billetera") {
      return;
    }

    if (currentPage === "retiro") {
      props.history.push("/consultaBilletera");
      props.refreshSaldo();
    }
    props.refreshSaldo();
  };

  const inicializarMensGral = () => {
    if (!props.logueado) return;

    let parametros = {
      userToken: props.estadoPlataforma.userToken,
    };

    let url =
      process.env.REACT_APP_URL_BASE_BACKEND +
      process.env.REACT_APP_WS_INIT_MENSAJERIA_GRAL;

    let statusCode = "";

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + parametros.userToken,
      },
    })
      .then((respPromise) => {
        statusCode = respPromise.status;
        if (respPromise.status == process.env.REACT_APP_CODIGO_CERRO_SESION) {
        }
        return respPromise.json();
      })
      .then((json) => {
        if (json.status === "ok") {
          setmensajesSinLeer(json.mensajesSinLeer);
        } else if (json.status === "error") {
        }
      })
      .catch((error) => {});
  };

  const ContactosTemplate = require(process.env
    .REACT_APP_PAGE_CONTACTO).default;
  const PreguntasFrecuentesTemplate = require(process.env
    .REACT_APP_PAGE_PREG_FREC).default;
  const JuegoResponsableTemplate = require(process.env
    .REACT_APP_PAGE_JUE_RESP).default;
  const MediosPagoTemplate = require(process.env
    .REACT_APP_PAGE_MEDIOS_PAGO).default;
  const AyudaPlataformaTemplate = require(process.env
    .REACT_APP_PAGE_AYUDA_PLATAFORMA).default;
  const TerminosyCondicionesTemplate = require(process.env
    .REACT_APP_PAGE_TERM_COND).default;

  return (
    <>
      {/*-------------------------------ACCESOS RAPIDOS LATERAL- LOGUEADO---------------------------------------------*/}
      {props.estadoPlataforma.estado === "PLATAFORMA" &&
      size > 820 &&
      height > 500 ? (
        /*<TopNavDesktop
          logueado={props.estadoPlataforma.logueado}
          volverAPlataforma={props.volverAPlataforma}
          consultarBilletera={consultarBilletera}
          refreshSaldo={props.refreshSaldo}
          estado={props.estadoPlataforma.estado}
          height={height}
          size={size}
          props={props}
          currentPage={currentPage}
          toggleMenu={toggleMenu}
          colorFont={colorFont}
        />*/
        <SideBarMenu
          logueado={props.estadoPlataforma.logueado}
          volverAPlataforma={props.volverAPlataforma}
          consultarBilletera={consultarBilletera}
        />
      ) : (
        <BottomNavMobile
          logueado={props.estadoPlataforma.logueado}
          volverAPlataforma={props.volverAPlataforma}
          consultarBilletera={consultarBilletera}
          refreshSaldo={props.refreshSaldo}
          estado={props.estadoPlataforma.estado}
          height={height}
        />
      )}

      <ContainerNav
        aplicarGradiente={false} //dejar en false
        //bordeDerecho={size <= 820 ? "1.3em" : "11%"}
        //bordeIzquierdo={size <= 820 ? "1.3em" : "11%"}
        bordeDerecho={size <= 820 ? "0em" : "0%"}
        bordeIzquierdo={size <= 820 ? "0em" : "0%"}
        className="container-fuid"
        color={colorFondo}
      >
        <div className="row align-items-center flex-nowrap">
          {
            /*!(size > 820 && height > 500) && (
            <>
              {*/ props.estadoPlataforma.estado === "PLATAFORMA" ? (
              <ColMenu className="col-1">
                {currentPage !== "registrarse" && (
                  <button
                    onClick={() => {
                      toggleMenu();
                    }}
                    className="navbar-toggler"
                  >
                    <span
                      color={colorFont}
                      className={"iconify"}
                      data-icon={"ant-design:menu-outlined"}
                      data-inline="false"
                      data-width="30px"
                    />
                  </button>
                )}
              </ColMenu>
            ) : (
              <ColMenu className="col-1">
                {currentPage !== "registrarse" && (
                  <Link
                    onClick={() => {
                      props.volverAPlataforma();
                    }}
                    to="/home"
                  >
                    <SpanFont
                      color={colorFont}
                      className="iconify"
                      data-icon="ant-design:left-outlined"
                      data-width="30px"
                    />
                  </Link>
                )}
              </ColMenu>
            )
            /*}
            
          )*/
          }
          <div className="col-3">
            {currentPage !== "usuario" && (
              <Link
                onClick={() => props.volverAPlataforma()}
                to="/misDatosPersonales"
              >
                <DivHolaUsuario>
                  <SpanFont color={colorFont} className="m-bold">
                    ¡Hola{" "}
                  </SpanFont>
                  <SpanFont color={colorFont}>
                    {props.estadoPlataforma.nombre.indexOf(" ") >= 0
                      ? props.estadoPlataforma.nombre.substr(
                          0,
                          props.estadoPlataforma.nombre.indexOf(" ")
                        )
                      : props.estadoPlataforma.nombre}
                    !
                  </SpanFont>
                </DivHolaUsuario>
              </Link>
            )}
            {currentPage === "usuario" && (
              <DivHolaUsuario>
                <SpanFont color={colorFont} className="m-bold">
                  {" "}
                  {props.estadoPlataforma.nombre}
                </SpanFont>
                {/*<SpanFont color={colorFont}>
                Esta es tu información de usuario
          </SpanFont>*/}
              </DivHolaUsuario>
            )}
          </div>
          <Col4Billetera
            top={size <= 820 ? "10vh" : "0"}
            className={
              size <= 820 ? "col-12 d-flex justify-content-center" : "col-5"
            }
            position={size <= 820 ? "absolute" : "relative"}
          >
            {currentPage !== "usuario" && currentPage !== "registrarse" && (
              <Animated animationIn="flipInX">
                <h5 onClick={clickRefrescarSaldo}>
                  <ContainerSaldo aplicarGradiente={true} className="container">
                    <div className="row">
                      <div className="col-2" style={{ padding: "6px" }}>
                        <span
                          className="iconify"
                          data-icon="ion:wallet-outline"
                          data-inline="false"
                          data-width="50px"
                        ></span>
                      </div>
                      <div className="col-2" style={{ padding: "6px" }}>
                        <span
                          className="m-bold"
                          style={{ textShadow: "3px 2px 4px grey" }}
                        >
                          Saldo<br></br>actual
                        </span>
                      </div>
                      <MontoSaldo color={"#01C160"} className="col-6">
                        <div>
                          {!mostrarSaldo ? (
                            <NumberFormat
                              value={props.estadoPlataforma.saldo}
                              displayType={"text"}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              prefix={"$ "}
                              decimalScale={2}
                              fixedDecimalScale={false}
                              isNumericString={true}
                              style={{
                                textShadow: "1px 2px 1px grey",
                                fontSize: "1.1em",
                              }}
                            />
                          ) : (
                            <span
                              style={{
                                textShadow: "1px 2px 1px grey",
                                fontSize: "100%",
                              }}
                            >
                              $ ***********
                            </span>
                          )}
                        </div>
                      </MontoSaldo>
                      {
                        /*["18", "48"].includes(codOrganizacion) && (*/
                        <div
                          className="col-2"
                          style={{
                            position: "fixed",
                            right: "0.5%",
                            top: "1em",
                          }}
                          onClick={handleClickOjoSaldo}
                        >
                          {estadoOjo && (
                            <Animated
                              animationIn="fadeIn"
                              animationInDuration={200}
                              animationOutDuration={200}
                              animateOnMount={false}
                              isVisible={estadoOjo}
                            >
                              <span
                                id="eye"
                                data-width="20px"
                                className="iconify op-text"
                                data-icon={"solar:eye-outline"}
                                data-inline="false"
                              />
                            </Animated>
                          )}
                          {!estadoOjo && (
                            <Animated
                              animationIn="fadeIn"
                              animationInDuration={200}
                              animationOutDuration={200}
                              animateOnMount={false}
                              isVisible={!estadoOjo}
                            >
                              <span
                                id="eye"
                                data-width="20px"
                                className="iconify op-text"
                                data-icon={"solar:eye-closed-outline"}
                                data-inline="false"
                              />
                            </Animated>
                          )}
                        </div>
                      }
                      {["13", "43"].includes(codOrganizacion) &&
                        size <= 820 && (
                          <div className="col-3 d-flex align-items-center justify-content-end">
                            <LogoJuegoResponsble
                              width={size >= 390 ? "50px" : "45px"}
                              height={size >= 390 ? "50px" : "45px"}
                              src={
                                require(`./img/icons/logo_juego_responsable_${codOrganizacion}.png`)
                                  .default
                              }
                              alt="Juego_Responsable_Logo"
                            />
                          </div>
                        )}
                    </div>
                  </ContainerSaldo>
                </h5>
              </Animated>
            )}
          </Col4Billetera>
          {["18", "48"].includes(codOrganizacion) && (
            <Col1TextAlignLast
              position={size <= 820 ? "absolute" : "relative"}
              right={
                size <= 820 && size >= 500 ? "18%" : size <= 500 ? "36%" : "0"
              }
              className={size <= 820 ? "col-1" : "col-1"}
            >
              {props.logueado &&
                props.estadoPlataforma.estado === "PLATAFORMA" && (
                  <JuegoResponsableLink
                    href={estilosProv[codOrganizacion].juegoResponsable}
                    target="_blank"
                    color={colorFont}
                    className="my-icon"
                  >
                    <ImagenJuegoresp
                      src={
                        require(`./img/icons/jugaxjugar${codOrganizacion}.png`)
                          .default
                      }
                      alt="Juego_Responsable_Link"
                    />
                  </JuegoResponsableLink>
                )}
            </Col1TextAlignLast>
          )}

          <Col1TextAlignLast
            position={size <= 820 ? "absolute" : "relative"}
            right={
              size <= 820 && size >= 500 ? "10%" : size <= 500 ? "22%" : "0"
            }
            className={size <= 820 ? "col-1" : "col-1"}
          >
            {props.logueado && (
              <div>
                {props.manualJuego !== undefined &&
                props.estadoPlataforma.estado !== "PLATAFORMA"
                  ? props.manualJuego
                  : props.logueado && (
                      <JuegoResponsableDiv
                        color={colorFont}
                        data-toggle="modal"
                        data-target={
                          props.logueado ? "#juegoResponsableModal" : ""
                        }
                        onClick={traerDatosJuegoResponsable}
                        className="my-icon"
                      >
                        <ImagenJuegoresp
                          src={
                            require(`./img/icons/juegoresp${
                              [
                                "41",
                                "11",
                                "53",
                                "23",
                                "13",
                                "43",
                                "20",
                                "50",
                                "18",
                                "48",
                              ].includes(codOrganizacion)
                                ? "blanco"
                                : ""
                            }.png`).default
                          }
                          alt="Juego_Responsable_Log"
                        />
                      </JuegoResponsableDiv>
                    )}
              </div>
            )}
          </Col1TextAlignLast>
          <Col1TextAlignLast
            position={size <= 820 ? "absolute" : "relative"}
            right={size <= 820 && size >= 500 ? "2%" : size <= 500 ? "8%" : "0"}
            className="col-1"
          >
            {props.notificacionesJuego !== undefined &&
            props.estadoPlataforma.estado !== "PLATAFORMA" ? (
              props.notificacionesJuego
            ) : (
              <div
                data-toggle="modal"
                data-target={props.logueado ? "#notiModal" : ""}
                onClick={traerMensGrales}
                style={{ width: "fit-content" }}
              >
                <div className="my-icon">
                  <SpanFont
                    color={colorFont}
                    className="iconify"
                    data-icon="bytesize:bell"
                    data-inline="false"
                    data-width="35px"
                    style={{ cursor: "pointer" }}
                  ></SpanFont>
                </div>

                {mensajesSinLeer !== 0 && (
                  <SpanNotificaciones
                    size={size}
                    className="badge badge-danger noti-badge "
                  >
                    {props.logueado ? mensajesSinLeer : ""}
                  </SpanNotificaciones>
                )}
              </div>
            )}
          </Col1TextAlignLast>
          {["13", "43"].includes(codOrganizacion) && size > 820 && (
            <LogoContainer>
              <LogoJuegoResponsble
                width="70px"
                src={
                  require(`./img/icons/logo_juego_responsable_${codOrganizacion}.png`)
                    .default
                }
                alt="Juego_Responsable_Logo"
              />
            </LogoContainer>
          )}
        </div>
        {/*-------------------------------------------------BURGUER MENU---------------------------------------------------------*/}

        <Burgermenu
          menu={menu}
          refsetWrapperRef={refsetWrapperRef}
          estadoPlataforma={props.estadoPlataforma}
          logueado={props.logueado}
          traerMensGrales={traerMensGrales}
          mensajesSinLeer={mensajesSinLeer}
          toggleMenu={toggleMenu}
          version={props.version}
          handleClickOutside={handleClickOutside}
          volverAPlataforma={props.volverAPlataforma}
          height={height}
          size={size}
        />

        {/*---------------------------MODAL NOTIFICACIONES--------------------------------------------*/}
        <ModalNotificaciones
          closeNotificacion={closeNotificacion}
          mensajesGenerales={mensajesGenerales}
        />
        {/*<div
        style={{ position: "fixed", top: "8%" }}
        className="modal fade"
        id="notiModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content pb-2">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                <span
                  className="iconify mr-2 mb-1"
                  data-icon="bytesize:bell"
                  data-inline="false"
                  data-width="24px"
                ></span>
                Notificaciones
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeNotificacion}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              className="modal-body"
              style={{ maxHeight: "300px", overflow: "scroll" }}
            >
              <table className="table table-striped text-center text-small">
                <tbody>
                  {mensajesGenerales.map((mensaje, index) => (
                    <tr
                      className={
                        mensaje.visto === false
                          ? "font-weight-bold"
                          : "bg-lightgray text-muted"
                      }
                      key={index}
                    >
                      <td>
                        {mensaje.fechaHora.substring(0, 10)}
                        <br></br>
                        {mensaje.fechaHora.substring(10, 16)}
                      </td>
                      <td className="pl-3 pr-3">{mensaje.texto}</td>
                    </tr>
                  ))}

                  {mensajesGenerales.length === 0 && (
                    <tr className="bg-lightgray text-muted">
                      <td className="pl-3 pr-3">
                        Aún no ha recibido notificaciones
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
*/}
        {/*---------------------------MODAL NEW GAME--------------------------------------------*/}

        <DivModalDialog
          style={{ zIndex: "1" }}
          className="modal fade"
          id="newGameModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="newGameModalLabel"
          aria-hidden="true"
          data-backdrop="false"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{
              position: "absolute",
              right: "0",
              bottom: "0",
              left: "0",
              width: "100%",
              maxWidth: "100%",
              minWidth: "100%",
            }}
          >
            <div
              className="modal-content pb-2"
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              <div
                hidden={props.estadoPlataforma.estado !== "PLATAFORMA"}
                className="modal-body pt-0 pb-0 pl-0 pr-0 bg-light up-shadow border"
                style={{
                  borderTopRightRadius: "25%",
                  borderTopLeftRadius: "25%",
                  background: "transparent !important",
                }}
              >
                <RowjuegosNav className="row justify-content-center">
                  <LlamadorJuego
                    size={size}
                    enableJuego={process.env.REACT_APP_ENABLE_TOMBOLA}
                    clickJuego={() => handleGeoLocalizacion(clickTombola)}
                    logoJuego={logoJuego0}
                    urljuego={
                      (errorGeo && requiereGeo) ||
                      requiereGeo === undefined ||
                      true
                        ? [
                            "13",
                            "43",
                            "11",
                            "41",
                            "42",
                            "12",
                            "20",
                            "50",
                          ].includes(process.env.REACT_APP_COD_ORGANIZACION)
                          ? "Quinielatradicional"
                          : "Tombola"
                        : "/"
                    }
                    estadoPlataforma={props.estadoPlataforma}
                    isNavBar={isNavBar}
                  />
                  <LlamadorJuego
                    size={size}
                    enableJuego={process.env.REACT_APP_ENABLE_TOMBO_EXPRESS}
                    clickJuego={() =>
                      handleGeoLocalizacion(clickTombolaExpress)
                    }
                    logoJuego={logoJuego1}
                    estadoPlataforma={props.estadoPlataforma}
                    isNavBar={isNavBar}
                    urljuego={
                      (errorGeo && requiereGeo) ||
                      requiereGeo === undefined ||
                      true
                        ? ["13", "43", "11", "41", "20", "50"].includes(
                            process.env.REACT_APP_COD_ORGANIZACION
                          )
                          ? "QuiniExpress"
                          : ["53", "23"].includes(
                              process.env.REACT_APP_COD_ORGANIZACION
                            )
                          ? "TomboExpress"
                          : "quinielainstantanea"
                        : "/"
                    }
                  />
                  <LlamadorJuego
                    size={size}
                    enableJuego={process.env.REACT_APP_ENABLE_PATA_MINIBINGO}
                    clickJuego={() => handleGeoLocalizacion(clickPataMinibingo)}
                    logoJuego={logoJuego2}
                    estadoPlataforma={props.estadoPlataforma}
                    isNavBar={isNavBar}
                    urljuego={
                      (errorGeo && requiereGeo) ||
                      requiereGeo === undefined ||
                      true
                        ? "patagoniaminibingo"
                        : "/"
                    }
                    tieneCuil={userCuil}
                  />
                  <LlamadorJuego
                    size={size}
                    enableJuego={process.env.REACT_APP_ENABLE_PATA_TELEBINGO}
                    clickJuego={() => handleGeoLocalizacion(clickPataTeleBingo)}
                    logoJuego={logoJuego3}
                    estadoPlataforma={props.estadoPlataforma}
                    isNavBar={isNavBar}
                    urljuego={
                      (errorGeo && requiereGeo) ||
                      requiereGeo === undefined ||
                      true
                        ? "patagoniatelebingo"
                        : "/"
                    }
                    tieneCuil={userCuil}
                  />{" "}
                  {logoJuego4 /*&&
                  (props.estadoPlataforma.apellido === "Torreblanca" ||
                    props.estadoPlataforma.apellido === "Saissac") */ && (
                    <LlamadorJuego
                      size={size}
                      enableJuego={process.env.REACT_APP_ENABLE_POZO}
                      clickJuego={clickPozoQuiniela}
                      cantCuponesJuego={cantCuponesJuego6}
                      logoJuego={logoJuego4}
                      urljuego={"pozodelaquiniela"}
                      estadoPlataforma={props.estadoPlataforma}
                      tieneCuil={userCuil}
                      isNavBar={isNavBar}
                    />
                  )}
                  {logoJuego5 /*&&
                  (props.estadoPlataforma.apellido === "Torreblanca" ||
                    props.estadoPlataforma.apellido === "Saissac") */ && (
                    <LlamadorJuego
                      size={size}
                      enableJuego={
                        process.env.REACT_APP_ENABLE_LOTERIA_UNIFICADA
                      }
                      clickJuego={clickLoteriaUnificada}
                      cantCuponesJuego={cantCuponesJuego10}
                      logoJuego={logoJuego5}
                      urljuego={"loteriaunificada"}
                      estadoPlataforma={props.estadoPlataforma}
                      tieneCuil={userCuil}
                      isNavBar={isNavBar}
                    />
                  )}
                  {}
                  {logoJuego6 /*&&
                  (props.estadoPlataforma.apellido === "Torreblanca" ||
                    props.estadoPlataforma.apellido === "Saissac")*/ && (
                    <LlamadorJuego
                      size={size}
                      enableJuego={process.env.REACT_APP_ENABLE_RASPADITA}
                      clickJuego={irARaspadita}
                      cantCuponesJuego={0}
                      logoJuego={logoJuego6}
                      urljuego={"raspadita_money"}
                      estadoPlataforma={props.estadoPlataforma}
                      tieneCuil={userCuil}
                      isNavBar={isNavBar}
                      propSide=""
                    />
                  )}
                </RowjuegosNav>
              </div>
            </div>
          </div>
        </DivModalDialog>

        <DivmodalSideNewGame
          className="modal fade"
          id="newGameModalDesktop"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="newGameModalDesktopLabel"
          aria-hidden="true"
          data-backdrop="false"
          //hidden={props.estadoPlataforma.estado !== "PLATAFORMA" || size < 820}
        >
          <div className="modal-dialog" role="document">
            <div
              className="modal-content pb-2"
              style={{ backgroundColor: "transparent", border: "none" }}
            >
              <div
                hidden={props.estadoPlataforma.estado !== "PLATAFORMA"}
                className="modal-body pt-3 pb-3 pl-0 pr-0 shadow"
                style={{ backgroundColor: "#ffffffa6", top: "-17px" }}
              >
                <div
                  className="row justify-content-center"
                  style={{ overflow: "scroll", height: "47vh" }}
                >
                  {" "}
                  <LlamadorJuego
                    size={size}
                    enableJuego={process.env.REACT_APP_ENABLE_TOMBOLA}
                    clickJuego={() => handleGeoLocalizacion(clickTombola)}
                    logoJuego={logoJuego0}
                    estadoPlataforma={props.estadoPlataforma}
                    isNavBar={isNavBar}
                    urljuego={
                      (errorGeo && requiereGeo) ||
                      requiereGeo === undefined ||
                      true
                        ? [
                            "13",
                            "43",
                            "11",
                            "41",
                            "42",
                            "12",
                            "20",
                            "50",
                          ].includes(process.env.REACT_APP_COD_ORGANIZACION)
                          ? "Quinielatradicional"
                          : "Tombola"
                        : "/"
                    }
                    propSide="col-12"
                  />
                  <LlamadorJuego
                    size={size}
                    enableJuego={process.env.REACT_APP_ENABLE_TOMBO_EXPRESS}
                    clickJuego={() =>
                      handleGeoLocalizacion(clickTombolaExpress)
                    }
                    logoJuego={logoJuego1}
                    estadoPlataforma={props.estadoPlataforma}
                    isNavBar={isNavBar}
                    urljuego={
                      (errorGeo && requiereGeo) ||
                      requiereGeo === undefined ||
                      true
                        ? ["13", "43", "11", "41", "20", "50"].includes(
                            process.env.REACT_APP_COD_ORGANIZACION
                          )
                          ? "QuiniExpress"
                          : ["53", "23"].includes(
                              process.env.REACT_APP_COD_ORGANIZACION
                            )
                          ? "TomboExpress"
                          : "quinielainstantanea"
                        : "/"
                    }
                    propSide="col-12"
                  />
                  <LlamadorJuego
                    size={size}
                    enableJuego={process.env.REACT_APP_ENABLE_PATA_MINIBINGO}
                    clickJuego={() => handleGeoLocalizacion(clickPataMinibingo)}
                    logoJuego={logoJuego2}
                    estadoPlataforma={props.estadoPlataforma}
                    isNavBar={isNavBar}
                    urljuego={
                      (errorGeo && requiereGeo) ||
                      requiereGeo === undefined ||
                      true
                        ? "patagoniaminibingo"
                        : "/"
                    }
                    propSide="col-12"
                    tieneCuil={userCuil}
                  />
                  <LlamadorJuego
                    size={size}
                    enableJuego={process.env.REACT_APP_ENABLE_PATA_TELEBINGO}
                    clickJuego={() => handleGeoLocalizacion(clickPataTeleBingo)}
                    logoJuego={logoJuego3}
                    estadoPlataforma={props.estadoPlataforma}
                    isNavBar={isNavBar}
                    urljuego={
                      (errorGeo && requiereGeo) ||
                      requiereGeo === undefined ||
                      true
                        ? "patagoniatelebingo"
                        : "/"
                    }
                    propSide="col-12"
                    tieneCuil={userCuil}
                  />
                  {logoJuego4 /*&& (props.estadoPlataforma.apellido === "Torreblanca" ||
                  props.estadoPlataforma.apellido === "Saissac") */ && (
                    <LlamadorJuego
                      size={size}
                      enableJuego={process.env.REACT_APP_ENABLE_POZO}
                      clickJuego={clickPozoQuiniela}
                      cantCuponesJuego={cantCuponesJuego6}
                      logoJuego={logoJuego4}
                      urljuego={"pozodelaquiniela"}
                      estadoPlataforma={props.estadoPlataforma}
                      tieneCuil={userCuil}
                      isNavBar={isNavBar}
                      propSide="col-12"
                    />
                  )}
                  {logoJuego5 /*(props.estadoPlataforma.apellido === "Torreblanca" ||
                  props.estadoPlataforma.apellido === "Saissac") && */ && (
                    <LlamadorJuego
                      size={size}
                      enableJuego={
                        process.env.REACT_APP_ENABLE_LOTERIA_UNIFICADA
                      }
                      clickJuego={clickLoteriaUnificada}
                      cantCuponesJuego={cantCuponesJuego10}
                      logoJuego={logoJuego5}
                      urljuego={"loteriaunificada"}
                      estadoPlataforma={props.estadoPlataforma}
                      tieneCuil={userCuil}
                      isNavBar={isNavBar}
                      propSide="col-12"
                    />
                  )}
                  {logoJuego6 && (
                    /*(props.estadoPlataforma.apellido === "Torreblanca" ||
                  props.estadoPlataforma.apellido === "Saissac")  && */ <LlamadorJuego
                      size={size}
                      enableJuego={process.env.REACT_APP_ENABLE_RASPADITA}
                      clickJuego={irARaspadita}
                      cantCuponesJuego={0}
                      logoJuego={logoJuego6}
                      urljuego={"raspadita_money"}
                      estadoPlataforma={props.estadoPlataforma}
                      tieneCuil={userCuil}
                      isNavBar={isNavBar}
                      propSide="col-12"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </DivmodalSideNewGame>

        {/*---------------------------MODAL CERRAR SESION--------------------------------------------*/}

        <div
          className="modal fade"
          id="cerrarSesionModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="cerrarSesionModalLabel"
          aria-hidden="true"
        >
          <div className="cerrar-sesion modal-dialog bg-light" role="document">
            <div className="modal-content pb-2">
              <div className="modal-body">
                <CerrarSesion
                  cerrarSesion={props.cerrarSesion}
                  estadoPlataforma={props.estadoPlataforma}
                />
              </div>
            </div>
          </div>
        </div>

        {/*---------------------------MODAL FAQ--------------------------------------------*/}

        <div
          style={{
            position: "fixed",
            top: "0",
            bottom: "0",
            overflow: "hidden",
          }}
          className="modal fade pb-5"
          id="faqModal"
          role="dialog"
          aria-labelledby="faqModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog ml-0 mr-0 mt-0" role="document">
            <div className="modal-content">
              <div className="modal-header bg-light">
                <h5 className="modal-title" id="faqModalLabel">
                  Preguntas Frecuentes
                </h5>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div
                className="modal-body text-center mb-2 text-small"
                style={{ overflowY: "scroll", height: "90vh" }}
              >
                <PreguntasFrecuentesTemplate />
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>

        {/*---------------------------MODAL TERMINOS Y CONDICIONES--------------------------------------------*/}

        <div
          style={{
            position: "fixed",
            top: "0",
            bottom: "0",
            overflowY: "hidden",
          }}
          className="modal fade pb-5"
          id="terminosModal"
          role="dialog"
          aria-labelledby="terminosModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog mt-0 mr-0 ml-0" role="document">
            <div className="modal-content">
              <div className="modal-header bg-light">
                <h5 className="modal-title" id="terminosModalLabel">
                  Terminos y Condiciones
                </h5>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div
                className="modal-body text-center mb-2 pb-5"
                style={{
                  overflowY: "scroll",
                  overflowX: "hidden",
                  height: "90vh",
                }}
              >
                {!props.estadoPlataforma.fetching &&
                  props.estadoPlataforma !== null &&
                  props.estadoPlataforma.tyc !== null &&
                  ReactHtmlParser(props.estadoPlataforma.tyc.tyc)}
              </div>

              {!props.estadoPlataforma.acceptedTyc && (
                <div
                  className="modal-footer bg-light"
                  style={{
                    position: "absolute",
                    right: "0",
                    left: "0",
                    bottom: "0",
                  }}
                >
                  <button
                    onClick={() => props.agreeTerm()}
                    className="btn btn-info mx-auto"
                  >
                    Acepto Términos y Condiciones
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        {/*---------------------------MODAL JUEGO RESPONSABLE--------------------------------------------*/}
        <div
          style={{ position: "fixed", top: "6%" }}
          className="modal fade"
          id="juegoResponsableModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content pb-2">
              <div className="modal-header">
                <h5
                  className="modal-title text-center"
                  id="juegoResponsableLabel"
                >
                  {juegoResponsable.titulo}
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div
                className="modal-body pt-0"
                style={{ maxHeight: "60vh", overflow: "scroll" }}
              >
                <div
                  className="modal-body text-center mb-2 pb-5"
                  style={{ overflowY: "scroll" }}
                >
                  <h4 className="m-bold mb-3 bg-dark text-light p-2 rounded">
                    Últimas 24 horas
                  </h4>
                  <div className="row mb-3">
                    <div className="col sm-3">
                      <div className="m-bold"> Premios </div>
                      <div style={{ fontSize: "23px" }}>
                        <div>&#36;{juegoResponsable.premiosJornada}</div>
                      </div>
                    </div>
                    <div className="col sm-3 mb-2">
                      <div className="m-bold">Jugado</div>
                      <div style={{ fontSize: "23px" }}>
                        <div>&#36; {juegoResponsable.jugadoJornada}</div>
                      </div>
                    </div>
                    <div className="col sm-3 mb-2">
                      <div className="m-bold">Balance</div>
                      <div style={{ fontSize: "23px" }}>
                        <div>&#36;{juegoResponsable.balanceJornada}</div>
                      </div>
                    </div>
                  </div>
                  <hr className="mb-4" />
                  <h4 className="m-bold mt-3 mb-3 bg-dark text-light p-2 rounded">
                    Datos de la sesión
                  </h4>
                  <p>
                    Usted lleva{" "}
                    <span className="m-bold text-danger">
                      {juegoResponsable.horasSesion}hrs{" "}
                      {juegoResponsable.minutosSesion}min
                    </span>{" "}
                    jugando
                  </p>
                  <div className="row mt-4">
                    <div className="col sm-3 mb-2">
                      <div className="m-bold">Premios</div>
                      <div style={{ fontSize: "23px" }}>
                        <div>&#36;{juegoResponsable.premiosSesion}</div>
                      </div>
                    </div>

                    <div className="col sm-3 mb-2">
                      <div className="m-bold">Jugado</div>
                      <div style={{ fontSize: "23px" }}>
                        <div>&#36;{juegoResponsable.jugadoSesion}</div>
                      </div>
                    </div>

                    <div className="col sm-3 mb-2">
                      <div className="m-bold">Balance</div>
                      <div style={{ fontSize: "23px" }}>
                        <div>&#36;{juegoResponsable.balanceSesion}</div>
                      </div>
                    </div>
                  </div>
                  <div>
                    {["42", "12"].includes(
                      process.env.REACT_APP_COD_ORGANIZACION
                    ) ? (
                      <>
                        {" "}
                        <a
                          href="https://ijan-neuquen.com/juego_responsable.php"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Juguemos con responsabilidad
                        </a>
                      </>
                    ) : (
                      <>Juguemos con responsabilidad</>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="modal-footer bg-light"
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                <button
                  className="btn btn-danger p-2 pl-3 pr-3"
                  onClick={props.cerrarSesion}
                >
                  CERRAR SESIÓN
                </button>
                <button
                  className="btn btn-success p-2 pl-3 pr-3"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  CONTINUAR
                </button>
              </div>
            </div>
          </div>
        </div>

        {/*---------------------------MODAL JUEGO RESPONSABLE--------------------------------------------*/}

        <div
          style={{
            position: "fixed",
            top: "0",
            bottom: "0",
            overflowY: "hidden",
          }}
          className="modal fade pb-5"
          id="responsableModal"
          role="dialog"
          aria-labelledby="responsableModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog mt-0 mr-0 ml-0" role="document">
            <div className="modal-content">
              <div className="modal-header bg-light">
                <h5 className="modal-title" id="responsableModalLabel">
                  Juego Responsable
                </h5>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div
                className="modal-body text-center mb-2 text-small"
                style={{ overflowY: "scroll", height: "90vh" }}
              >
                <JuegoResponsableTemplate />
              </div>
            </div>
          </div>
        </div>

        {/*---------------------------MODAL CONTACTO--------------------------------------------*/}

        <div
          style={{ position: "fixed", top: "8%", overflowY: "scroll" }}
          className="modal fade pb-5"
          id="contactoModal"
          role="dialog"
          aria-labelledby="contactoModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header bg-light">
                <h5 className="modal-title" id="contactoModalLabel">
                  Contacto
                </h5>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="modal-body text-center mb-2 text-small">
                <ContactosTemplate />
              </div>
            </div>
          </div>
        </div>

        {/*---------------------------MODAL MEDIOS DE PAGO--------------------------------------------*/}

        <div
          style={{
            position: "fixed",
            top: "0",
            bottom: "0",
            overflow: "hidden",
          }}
          className="modal fade"
          id="mediosModal"
          role="dialog"
          aria-labelledby="mediosModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog mt-0 mr-0 ml-0" role="document">
            <div className="modal-content">
              <div className="modal-header bg-light">
                <h5 className="modal-title" id="mediosModalLabel">
                  Medios de pago
                </h5>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div
                className="modal-body text-center text-small"
                style={{ overflowY: "scroll", height: "90vh" }}
              >
                <MediosPagoTemplate />
              </div>
            </div>
          </div>
        </div>

        {/*---------------------------MODAL USO DE PLATAFORMA--------------------------------------------*/}

        <div
          style={{
            position: "fixed",
            top: "0",
            bottom: "0",
            overflow: "hidden",
          }}
          className="modal fade"
          id="usoModal"
          role="dialog"
          aria-labelledby="usoModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog mt-0 mr-0 ml-0" role="document">
            <div className="modal-content">
              <div className="modal-header bg-light">
                <h5 className="modal-title" id="usoModalLabel">
                  Uso de la plataforma
                </h5>

                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div
                className="modal-body text-center text-small"
                style={{ overflowY: "scroll", height: "90vh" }}
              >
                <AyudaPlataformaTemplate />
              </div>
            </div>
          </div>
        </div>

        <Calificar estadoPlataforma={nps} />
      </ContainerNav>
    </>
  );
};

export default withRouter(NavBar);

const JuegoResponsableLink = styled.a`
  cursor: pointer;
  color: ${(props) => props.color};
  width: fit-content;
`;

const JuegoResponsableDiv = styled.div`
  cursor: pointer;
  color: ${(props) => props.color};
  width: fit-content;
`;

const ContainerNav = styled.div`
  background-color: ${(props) => props.color};
  padding: 0.5rem;
  border-bottom-left-radius: ${(props) => props.bordeIzquierdo};
  border-bottom-right-radius: ${(props) => props.bordeDerecho};
  margin-bottom: 0vh;
  ${(props) =>
    props.aplicarGradiente ? "box-shadow: 1px 6px 8px 0px #858f8a" : ""}
  width: 100%;
  z-index: 3;
  min-height: 13vh;
  align-content: center;
  top: 0;
`;

export const SpanFont = styled.span`
  color: ${(props) => props.color};
  font-size: x-large;
  text-align: center;
`;

const DivHolaUsuario = styled.div`
  white-space: nowrap;
  font-size: x-large;
  text-align: center;
`;

export const ColMenu = styled.div`
  margin-right: 1rem;
  text-align-last: justify;
`;

const ContainerSaldo = styled.div`
  background-color: #f1f1f1;
  border-radius: 1em;
  ${(props) =>
    props.aplicarGradiente ? "box-shadow: 8px 8px 7px 2px #2826268a" : ""};
  min-width: 20rem;
  max-width: 100%;
`;

const MontoSaldo = styled.div`
  color: ${(props) => props.color};
  text-align: center;
  padding-top: 1em;
  font-family: "Mont-Bold";
  white-space: nowrap;
`;

const ImagenJuegoresp = styled.img`
  width: 30px;
`;

const LogoJuegoResponsble = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

const Col1TextAlignLast = styled.div`
  position: ${(props) => props.position};
  text-align: center;
  right: ${(props) => props.right};
`;

const LogoContainer = styled.div`
  position: ${(props) => props.position};
  text-align: right;
  right: ${(props) => props.right};
  top: ${(props) => props.top};
`;

const Col4Billetera = styled.div`
  position: ${(props) => props.position};
  top: ${(props) => props.top};
  z-index: 3;
  left: 0%;
`;

const DivModalDialog = styled.div`
  left: 0 !important;
`;

const DivmodalSideNewGame = styled.div`
  z-index: 1;
  top: 14rem !important;
  left: 6rem !important;
  width: 12em !important;
`;

const RowjuegosNav = styled.div`
  flex-wrap: nowrap;
  overflow: auto;
  padding-left: 2em;
`;

const SpanNotificaciones = styled.span`
  left: ${(props) => (props.size <= 500 ? "2vw" : "0.7vw")};
  top: 0px;
`;
